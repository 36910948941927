// src/utils/getUserLocation.js
// import axios from 'axios';
import axios from "axios";


const getUserLocation = async () => {
  try {
    const response = await axios.get('https://ipinfo.io/json?token=314fb01a59e05b');
    return response.data;
  } catch (error) {
    console.error('Error fetching the user location', error);
    return null;
  }
};

export default getUserLocation;
