import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li className="has-droupdown"><Link to="">Home</Link>
               
            </li>
            
            <li><Link to="/about-us">About</Link></li>

            <li className="has-droupdown"><Link to="#">Services</Link>
                <ul className="submenu">
                    <li><Link to="/app-development">App Development</Link></li>
                    <li><Link to="/web-design-and-development">Web Design and Development</Link></li>
                    <li><Link to="/web-application">Web Application</Link></li>
                    <li><Link to="/ecommerce-solution">eCommerce Solution</Link></li>
                    <li><Link to="/crm-solutions">CRM Solutions</Link></li>
                    <li><Link to="/email-marketing">Email Marketing</Link></li>
                    <li><Link to="/video-editing">Video Editing</Link></li>
                    <li><a href="https://whatson.agency/billing/cart.php?a=add&domain=register" target="_blank">Domain Register</a></li>
                    <li><Link to="/hosting">Web Hosting</Link></li>
                    <li><Link to="/seo">SEO</Link></li>
                    <li><Link to="/facebook-promotion-and-boosting">Facebook Boosting</Link></li>
                    <li><Link to="/youtube-advertisement">YouTube Advertisement</Link></li>
                    <li><Link to="/google-advertisement">Google Advertisement</Link></li>
                </ul>
            </li>

            <li className=""><Link to="/blogs">Blog</Link>
                {/* <ul className="submenu">
                    <li><Link to="/blog-grid">Blog Grid</Link></li>
                    <li><Link to="/blog-grid-sidebar">Blog Grid Sidebar</Link></li>
                    <li><Link to="/blog-list-view">Blog List View</Link></li>
                    <li><Link to="/blog-list-sidebar">Blog List View Sidebar</Link></li>
                </ul> */}
            </li>

            <li className="#"><Link to="/portfolio">Portfolio</Link></li>
            <li className="#"><Link to="/Contact">Contact</Link></li>
            <li><a href="https://whatson.agency/billing/index.php?rp=/login" target="_blank">Client Area</a></li>
        </ul>
    )
}
export default Nav;
