import React from 'react';

const Copyright = () => {
    return (
        <div className="copyright-area copyright-style-one">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                        <div className="copyright-left">
                            <ul className="ft-menu link-hover">
                                {/* <li><a href="https://whatson.agency/privacy-policy/" target="_blank">Privacy Policy</a></li>
                                <li><a href="/terms-and-conditions" target="_blank">Terms and Conditions</a></li> */}
                                <li><a href="/Contact">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-12 col-12">
                        <div className="copyright-right text-center text-md-end">
                            <p className="copyright-text">
                                Digital Agency by WhatsOn © All rights reserved {new Date().getFullYear()}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Copyright;
