import React, { useState, useEffect } from "react";
import axios from 'axios';
import PortfolioItem from "./PortfolioItem";
import { FaSpinner } from "react-icons/fa";

const PortfolioOne = ({ Column }) => {
    const [allItems, setAllItems] = useState([]);
    const [visibleItems, setVisibleItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dataVisibleCount, setDataVisibleCount] = useState(6);
    const [noMorePost, setNoMorePost] = useState(false);
    const [activeFilter, setActiveFilter] = useState("all");
    const [categories, setCategories] = useState([]);

    const allowedCategoryIds = [14, 15, 18, 19]; // Define the allowed category IDs here

    const staticCategoryMap = {
        14: "eCommerce",
        15: "Education",
        18: "Personal",
        19: "Venus / Events"
        // Add other categories here as needed
    };

    useEffect(() => {
        const fetchAllPortfolios = async () => {
            let allFetchedItems = [];
            let page = 1;
            let totalPages = 1;

            try {
                while (page <= totalPages) {
                    const response = await axios.get(`https://new.whatson.agency/wp-json/wp/v2/portfolios?_fields=acf,id,title&acf_format=standard&per_page=100&page=${page}`);

                    allFetchedItems = [...allFetchedItems, ...response.data];

                    const totalItems = response.headers['x-wp-total'];
                    const totalPagesInAPI = response.headers['x-wp-totalpages'];
                    totalPages = totalPagesInAPI ? parseInt(totalPagesInAPI) : Math.ceil(totalItems / 100);

                    page++;
                }

                // Filter items by allowed categories
                const filteredItems = allFetchedItems.filter(item =>
                    item.acf.portfolio_category.some(category => allowedCategoryIds.includes(category))
                );

                setAllItems(filteredItems);
                setVisibleItems(filteredItems.slice(0, dataVisibleCount));
                setLoading(false);

                const categorySet = new Set();
                filteredItems.forEach(item => {
                    item.acf.portfolio_category.forEach(category => {
                        if (allowedCategoryIds.includes(category)) {
                            categorySet.add(category);
                        }
                    });
                });

                const combinedCategories = { ...staticCategoryMap };
                const filteredCategories = Array.from(categorySet).reduce((acc, category) => {
                    if (combinedCategories[category]) {
                        acc[category] = combinedCategories[category];
                    }
                    return acc;
                }, {});

                setCategories(Object.entries(filteredCategories));
            } catch (error) {
                console.error('Error fetching portfolios:', error);
                setLoading(false);
            }
        };

        fetchAllPortfolios();
    }, [dataVisibleCount]);

    const handleChange = (category) => {
        setActiveFilter(category);
        let filteredItems;
        if (category === "all") {
            filteredItems = allItems.slice(0, dataVisibleCount);
        } else {
            filteredItems = allItems.filter(
                (item) => item.acf.portfolio_category.includes(parseInt(category))
            ).slice(0, dataVisibleCount);
        }
        setVisibleItems(filteredItems);
    };

    const handleLoadMore = () => {
        const newCount = dataVisibleCount + 6;
        setDataVisibleCount(newCount);

        const additionalItems = activeFilter === "all"
            ? allItems.slice(0, newCount)
            : allItems.filter((item) => item.acf.portfolio_category.includes(parseInt(activeFilter))).slice(0, newCount);

        setVisibleItems(additionalItems);

        if (newCount >= allItems.length) {
            setNoMorePost(true);
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <ul className="rwt-portfolio-filter filter-button-default liststyle mb--20">
                        <li className="list-item">
                            <button
                                onClick={() => handleChange("all")}
                                className={activeFilter === "all" ? "current" : ""}
                            >
                                All
                            </button>
                        </li>
                        {categories.map(([categoryId, categoryName]) => (
                            <li className="list-item" key={categoryId}>
                                <button
                                    onClick={() => handleChange(categoryId)}
                                    className={activeFilter === categoryId ? "current" : ""}
                                >
                                    {categoryName}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="row row--15">
                {visibleItems.map((item) => (
                    <div key={item.id} className={Column}>
                        <PortfolioItem portfolio={item} categoryMap={staticCategoryMap} />
                    </div>
                ))}
            </div>

            {/* <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rwt-load-more text-center mt--50">
                        <button
                            className="btn btn-default btn-large btn-icon"
                            onClick={handleLoadMore}
                            disabled={noMorePost}
                        >
                            {noMorePost ? (
                                "No Item Here"
                            ) : (
                                <span>
                                    Load More
                                    <span className="icon">
                                        <FaSpinner />
                                    </span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default PortfolioOne;
